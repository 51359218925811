.header-art {
  border-color: var(--main-accent-color);
  border-left-style: double;
  border-width: 3px;
  display: none;
  grid-area: hdrart;
  height: 13em;
  margin-top: 1.5em;
}

@media (min-width: 35em) {
  .header-art {
    display: block;
  }
}

.header-art::before {
  background-color: var(--main-accent-color);
  content: "";
  height: 1px;
  margin-left: -7em;
  position: absolute;
  width: 23em;
}

.header-art > span {
  color: var(--main-text-color);
  display: block;
  font-family: MyUniverse;
  opacity: 0.10;
  position: relative;
}

.hw1 {
  font-size: 3em;
  left: 50%;
  top: 20%;
}

.hw2 {
  font-size: 7.5em;
  left: 15%;
  top: -5%;
}

.hw3 {
  font-size: 4em;
  left: 10%;
  top: -25%;
}

.hw4 {
  font-size: 2.5em;
  left: 52%;
  top: -40%;
}
