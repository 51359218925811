.knowledge > div.media--center {
  margin-bottom: 2em;
}

.experience {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.experience .tile {
  flex: 0 0 100%;
}

@media (min-width: 35em) {
  .experience .tile {
    flex: 0 0 50%;
    margin-bottom: 1.5em;
    padding-right: 1.5em;
  }
}

.experience .items {
  line-height: 110%;
  list-style: square;
  list-style-position: outside;
  padding-left: 1.5em;
}

.experience .items li {
  margin: 5px 0 5px 0;
}

.experience .items ul li ul li {
  margin: 5px 0 5px 0;
}

.experience .items ul {
  list-style: circle;
  margin: 0 0 0 0;
  padding-left: 24px;
}
