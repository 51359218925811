.contact {
  width: 100%;
}

@media (min-width: 35em) {
  .contact {
    display: flex;
  }
}

.contact > div {
  margin: 2em 0;
}
