@media (min-width: 35em) {
  main {
    grid-area: main;
  }

  main::before {
    border-color: var(--main-accent-color);
    border-top-style: solid;
    border-width: 1px;
    content: "";
    position: absolute;
    width: 300px;
  }
}

.content {
  font-size: 0.8em;
  line-height: 1.4em;
  padding: 0.75em;
}

@media (min-width: 35em) {
  .content {
    font-size: 1.06em;
    padding: 2em;
  }
}

.content p + p {
  margin-top: 1.5em;
}

.content .img_left {
  float: left;
  margin-top: 0.2em;
  padding: 0 1em 0 0;
  width: 6em;
}

@media (min-width: 35em) {
  .content .img_left {
    width: inherit;
  }
}

.content h2 {
  margin: 0.5em 0;
}

.content ul {
  margin: 1em 0 1em 2em;
}
