.logo {
  margin-top: 0.5em;
  width: 100%;
}

@media (min-width: 35em) {
  .logo {
    grid-area: logo;
    margin-top: 1.5em;
  }
}

.logo img {
  display: block;
  margin: auto;
  width: 5em;
}

@media (min-width: 35em) {
  .logo img {
    margin-left: 13em;
    margin-top: 1em;
    width: inherit;
  }
}
