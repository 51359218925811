nav {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--main-accent-color);
}

@media (min-width: 35em) {
  nav {
    border-right-style: double;
    border-right-width: 3px;
    grid-area: nav;
  }
}

.site-nav {
  display: flex;
  font-family: MyUniverse;
  font-size: 1.25em;
  padding-bottom: 0.25em;
  text-align: center;
}

@media (min-width: 35em) {
  .site-nav {
    display: block;
    font-size: 2.3em;
    padding-right: 1em;
    padding-top: 1em;
    text-align: right;
  }
}

.site-nav > li {
  flex: 25%;
}

@media (min-width: 35em) {
  .site-nav > li {
    display: block;
    flex: inherit;
    padding-bottom: 0.3em;
    padding-right: 0;
  }
}

.site-nav > li > a {
  color: var(--main-text-color);
  text-decoration: underline;
}

@media (min-width: 35em) {
  .site-nav > li > a {
    text-decoration: none;
    transition-property: all;
    transition-duration: 0.2s;
  }
}

.site-nav > li > a:hover {
  color: var(--main-accent-color);
  cursor: pointer;
}

.site-nav > li > a.current-content {
  color: var(--main-accent-color);
  text-decoration: underline;
}

@media (min-width: 35em) {
  .site-nav > li > a.current-content {
    color: var(--main-text-color);
    text-decoration: underline;
  }
}
