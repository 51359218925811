.portfolio > div {
  margin-bottom: 2em;
}

.past-projects {
  flex-direction: row;
  flex-wrap: wrap;
}

.past-projects .tile {
  flex: 1;
  padding-right: 1.5em;
}

@media (min-width: 35em) {
  .past-projects .tile {
    margin-bottom: 1.5em;
    width: 50%;
  }
}

.past-projects .tile img {
  float: right;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}
