.page {
  margin: 0 auto;
  max-width: 1000px;
}

@media (min-width: 35em) {
  .page {
    display: grid;
    grid-gap: 0;
    grid-template-areas:
      "logo   logo   hdrart"
      "midpad midpad midpad"
      "nav    main   main  "
      "nav    footer footer";
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-rows: repeat(4, auto);
  }
}

.spacer {
  height: 1em;
}

@media (min-width: 35em) {
  .spacer {
    grid-area: midpad;
    height: 2em;
  }
}
